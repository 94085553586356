import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import "../new/_main.scss"
import useScrollListener from "../hooks/useScrollListener"
import Header from "./v2023/Header/header"
import Footer from "./v2023/Footer/footer"
import { withPrefix } from "gatsby-link"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children, pageContext }) => {

    const [navClassList, setNavClassList] = useState([])
    const scroll = useScrollListener()
    const path = {
        "en": "/public-financing",
        "es": "/es/financiacion-publica"
    }
    const path2 = {
        "en": "/compliance-legal-legality-tecalis-eidas-qtsp-regulations-compliance-certifications-stamp",
        "es": "/es/cumplimiento-normativo-legal-legalidad-tecalis-eidas-qtsp-regulaciones-compliance-certificaciones-sellos"
    }
    const lang = pageContext.langKey

    // update classList of nav on scroll
    useEffect(() => {
        const _classList = []

        if (scroll.y > 150)
            _classList.push("hidden")

        setNavClassList(_classList)
    }, [scroll.y, scroll.lastY])

    return (
        <>
            <Helmet>
                <title>Tecalis</title>
                <meta name="description" content="web 2022" />
                <meta name="p:domain_verify" content="803e8c951a2ad570b30570ddfdda48d9"/>
                <link rel="stylesheet" href="https://use.typekit.net/acr2trl.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
                      integrity="sha512-NhSC1YmyruXifcj/KFRWoC561YpHpc5Jtzgvbuzx5VozKpWvQ+4nXhPdFgmx8xqexRcpAglTj9sIBWINXa8x5w==" crossorigin="anonymous"
                      referrerpolicy="no-referrer" />
                <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js" />
                <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
                <script charSet="utf-8" type="text/javascript" src={withPrefix("scripts/hotjar.js")} />
            </Helmet>
            <Header lang={pageContext.langKey} translates={pageContext.translates} />
            {children}
            <div>
                <div className={`floating-button ${navClassList.join(' ')}`}>
                    <Link to={path2[lang]}>
                        <div className="floating-button__image">
                            <StaticImage
                                src={"../new/resources/home/qtsp.png"}
                                alt="Union europea"
                                placeholder="blurred"
                            />
                        </div>
                    </Link>
                    <Link to={path[lang]}>
                        <div className="floating-button__image">
                            <StaticImage
                                src={"../new/resources/home/eu-en.png"}
                                alt="Union europea"
                                placeholder="blurred"
                            />
                        </div>
                    </Link>
                </div>
            </div>
            <Footer lang={pageContext.langKey} translates={pageContext.translates} />
        </>
    )
}


export default Layout
